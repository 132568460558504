import React from 'react';
import './Hero.css'; // Import the CSS file for styling
import firstimaeg from "./1.png"; import secund from "./2.png"; import third from "./3.png"; import four from "./4.png"
import { Link } from 'react-router-dom';
const Hero = () => {
    return (
        <div className="financial-container">
            {/* Header Section */}
            <div className="header-section">
                <h1>Discover AI Insights and Tools for<br /> Growth on <span className="highlight">Ailogixx.com</span></h1>
                <p>
                    Elevate your understanding of AI with our expert resources and insights. Discover and master<br /> the latest AI tools to stay at the forefront of innovation

                </p>
                {/* Buttons */}
                <div className="button-group">
                    <Link to="/blogs">   <button className="tool-btn">Discover Now</button></Link>

                </div>
            </div>

            {/* Images Section */}
            <div className="images-section">

                <img src={secund} alt="Teamwork" className="image-item" /> <img src={four} alt="Teamwork" className="image-item" />
                <img src={third} alt="Teamwork" className="image-item" />

                <img src={firstimaeg} alt="Financial planning" className="image-item" />
            </div>
        </div>
    );
};

export default Hero;
