import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import Bottombar from '../bottombar/Bottombar'
import Buttonads from '../Ads/Buttonads.js'
function Footer() {
    return (<> <Bottombar /> <div className='footer'>
        <div className='container'>
            <div className='row'>
                <div class="footer-legal text-center position-relative">
                    <div class="container">
                        <div class="copyright">
                            &copy; Copyright <strong><span>AILogixX</span></strong> All Rights Reserved
                        </div>
                        <div class="">

                            Designed by <a href="">AILogixxs</a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div></>

    )
}

export default Footer