import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../Source/firebaseConfig";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import "./Like.css";

export default function LikeArticle({ id, likes }) {
  const [user] = useAuthState(auth);

  const likesRef = doc(db, "Articles", id);

  const handleLike = () => {
    if (likes?.includes(user.uid)) {
      updateDoc(likesRef, {
        likes: arrayRemove(user.uid),
      }).then(() => {
        console.log("unliked");
      }).catch((e) => {
        console.log(e);
      });
    } else {
      updateDoc(likesRef, {
        likes: arrayUnion(user.uid)
      }).then(() => {
        console.log("liked");
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <div className="LikeIcon">
      <i
        className={`fa fa-heart${!likes?.includes(user.uid) ? "-o" : ""} fa-lg`}
        style={{ fontSize: "25px", cursor: "pointer", color: likes?.includes(user.uid) ? "red" : null }}
        onClick={handleLike}
      />
    </div>
  );
}
