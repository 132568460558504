import React, { useState, useEffect } from "react";
import { collection, onSnapshot, orderBy, query, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../Source/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import DeleteArticle from "../Tools/Tool/LikeDeleteCooment/DeleteArticle.jsx";
import LikeArticle from "../Tools/Tool/LikeDeleteCooment/LikeArticle.jsx";
import Footer from "../Footer/Footer";
import "./Tools.css";
import Loder from "../Loder/Loder";

// Utility function to remove HTML tags
const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
};

function Tools() {
    const [articles, setArticles] = useState([]);
    const [user] = useAuthState(auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading indicator

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 18;

    useEffect(() => {
        const articleRef = collection(db, "Tools");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
            setFilteredArticles(articles); // Initially set filtered articles to all articles
            setLoading(false); // Set loading to false when data is loaded
        });
    }, []);

    useEffect(() => {
        // Filter articles based on searchQuery
        const filtered = articles.filter(article =>
            article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            article.Type.toLowerCase().includes(searchQuery.toLowerCase()) ||
            stripHtmlTags(article.content).toLowerCase().includes(searchQuery.toLowerCase()) // Strip HTML tags here
        );
        setFilteredArticles(filtered);
        setCurrentPage(1); // Reset to first page when search query changes
    }, [searchQuery, articles]);

    const handleLike = async (id, currentLikes) => {
        // Update likes count in Firestore
        const articleRef = doc(db, "Tools", id);
        await updateDoc(articleRef, {
            likes: currentLikes + 1, // Increment likes by 1
        });
    };

    const handleImageLoad = (e) => {
        e.target.classList.add("loaded"); // Add the loaded class when the image has fully loaded
    };

    // Function to truncate the content to a fixed number of lines
    const truncateContent = (content, lineCount) => {
        const text = stripHtmlTags(content); // Strip HTML tags
        const maxChars = 100 * lineCount; // Estimate max characters per line
        return text.length > maxChars ? text.slice(0, maxChars) + '...' : text;
    };

    // Pagination logic
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Skeleton loading UI
    const SkeletonLoading = () => (
        <div className="row gy-4">
            {[1, 2, 3].map((item) => (
                <div className="col-xl-4 col-md-6" key={item}>
                    <div className="skeleton-loading">

                    </div>
                </div>
            ))}
        </div>
    );

    return (<>  <div id="recent-posts" className="recent-posts">
        <div className="container">
            {loading ? (
                <div className="loader-container">
                    <Loder /> {/* Add your custom loader style here */}
                </div>
            ) : (
                <>
                    <h6 className="counter">Number of Tools Available: {filteredArticles.length}</h6>

                    <div className="search-bar">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search For Tool You Need ...."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    {filteredArticles.length === 0 && searchQuery !== "" ? (
                        <div className="no-results-message">
                            <p>No tools found.</p>
                        </div>
                    ) : (
                        <>
                            <div className="row gy-4">
                                {currentArticles.length === 0 ? (
                                    <SkeletonLoading /> // Show skeleton loading if no articles are available
                                ) : (
                                    currentArticles.map(({ Author, id, title, content, imageUrl, createdAt, createdBy, userId, Type, likes, OfficalLink }) => (
                                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100" id="itesmss" key={id}>
                                            <article>
                                                <Link to={`/Tool/${id}`} style={{ textDecoration: "none" }}>
                                                    <div className="post-img">
                                                        <img src={imageUrl} alt="" className="img-fluid" loading="lazy" onLoad={handleImageLoad} />
                                                    </div>

                                                    <div className="likecommentcontiner">
                                                        <div className="deleteArtical">
                                                            {user && user.uid === userId && (
                                                                <DeleteArticle id={id} imageUrl={imageUrl} />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <Link to={`/Tool/${id}`} style={{ textDecoration: "none", marginBottom: "20px" }}>
                                                        <h2 className="title" style={{ marginBottom: "20px" }}>
                                                            <a href="blog-details.html">{title}</a>
                                                        </h2>
                                                    </Link>
                                                    <p className="" style={{ color: "black" }}>
                                                        {truncateContent(content, 2)} {/* Truncate to 3.5 lines */}
                                                    </p>
                                                    <p className="post-category">{Type}</p>
                                                </Link>
                                            </article>
                                        </div>
                                    ))
                                )}
                            </div>
                            {/* Pagination */}
                            <ul className="pagination" style={{ marginTop: "30px" }}>
                                {Array.from({ length: Math.ceil(filteredArticles.length / articlesPerPage) }, (_, i) => (
                                    <li key={i} style={{ marginRight: "2px" }} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <a href="#!" className="page-link" onClick={() => paginate(i + 1)}>
                                            {i + 1}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </>
            )}
        </div>

    </div>  <Footer /></>

    );
}

export default Tools;
