import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Source/firebaseConfig";
import { signOut } from "firebase/auth";
import './Navbar.css';
import Logo from "./junaid-removebg-preview.png";

const Navbar = () => {
  const [user] = useAuthState(auth);
  const collapseRef = useRef(null);

  const handleNavLinkClick = () => {
    if (collapseRef.current && collapseRef.current.classList.contains('show')) {
      collapseRef.current.classList.remove('show');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="navbar-logo-wrapper">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Logo" className="navbar-logo" />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          ref={collapseRef}
        >
          <ul className="navbar-nav nav-links">
            <li className="nav-item">
              <Link className="nav-link" to="/" onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Blogs" onClick={handleNavLinkClick}>Blogs</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Tools" onClick={handleNavLinkClick}>Tools</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Aboutus" onClick={handleNavLinkClick}>About  us </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Support" onClick={handleNavLinkClick}>Contact us </Link>
            </li> <li className="nav-item">
              <Link className="nav-link" to="/Login" onClick={handleNavLinkClick}>Login</Link>
            </li>
          </ul>
          <div className="ms-auto">
            {user && (
              <>
                <span className="pe-4">{user.displayName || user.email}</span>
                <button className="btn btn-primary btn-sm" onClick={() => { signOut(auth); }}>Logout</button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
