import React, { useState, useEffect } from "react";
import { collection, onSnapshot, orderBy, query, doc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../Source/firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import DOMPurify from 'dompurify';
import "./Recenttools.css";
import Loder from "../../Loder/Loder";

function Recenttools() {
    const [articles, setArticles] = useState([]);
    const [user] = useAuthState(auth);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredArticles, setFilteredArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const articleRef = collection(db, "Tools");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
            setFilteredArticles(articles);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        const filtered = articles.filter(article =>
            article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            article.Type.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (article.content && article.content.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        setFilteredArticles(filtered);
    }, [searchQuery, articles]);

    const handleLike = async (id, currentLikes) => {
        const articleRef = doc(db, "Tools", id);
        await updateDoc(articleRef, {
            likes: currentLikes + 1,
        });
    };

    const handleImageLoad = (e) => {
        e.target.classList.add("loaded");
    };

    const currentArticles = filteredArticles.slice(0, 3);

    const SkeletonLoading = () => (
        <div className="row gy-4">
            {[1, 2, 3].map((item) => (
                <div className="col-xl-4 col-md-6" key={item}>
                    <div className="skeleton-loading">
                        <div className="skeleton-item"></div>
                        <div className="skeleton-item"></div>
                        <div className="skeleton-item"></div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div id="recent-posts" className="recent-posts">
            <div className="container">
                <h3 className="latest-tools">New Tools</h3>
            </div>
            <div className="container">
                {loading && (
                    <div className="loader-container">
                        <Loder />
                    </div>
                )}
                {!loading && (
                    <>
                        {filteredArticles.length === 0 && searchQuery !== "" ? (
                            <div className="no-results-message">
                                <p>No tools found.</p>
                            </div>
                        ) : (
                            <div className="row gy-4">
                                {currentArticles.map(({ id, title, content = "", imageUrl, Type }) => {
                                    const sanitizedContent = DOMPurify.sanitize(content.length > 150 ? content.slice(0, 150) + "..." : content);
                                    return (
                                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100" key={id}>
                                            <article>
                                                <Link to={`/Tool/${id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <div className="post-img">
                                                        <img src={imageUrl} alt={title} className="img-fluid" loading="lazy" onLoad={handleImageLoad} />
                                                    </div>
                                                    <h2 className="title">
                                                        {title}
                                                    </h2>
                                                    <p dangerouslySetInnerHTML={{ __html: sanitizedContent }}></p>
                                                    <p className="post-category">{Type}</p>
                                                </Link>
                                            </article>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default Recenttools;
