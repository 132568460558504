import React from 'react'
import './Home.css'
import Footer from '../Footer/Footer'
import Hero from './Hero/Hero'

import Recenttools from "./Recenttools/Recenttools"
import LatestBlogs from './LatestBlogs/LatestBlogs'

function Home() {
    return (
        <div>
            <Hero />
            <LatestBlogs />
            <Recenttools />
            <Footer />

        </div >
    )
}

export default Home