import React from 'react'
import "./Loder.css"
function Loder() {
    return (
        <div className='continerofloder'>
            <div class="loader">
                <div class="dot dot-1"></div>
                <div class="dot dot-2"></div>
                <div class="dot dot-3"></div>
                <div class="dot dot-4"></div>
                <div class="dot dot-5"></div>
            </div>
        </div>
    )
}

export default Loder