import React from 'react'
import About from '../About/About'
import Toolsintro from '../Toolsintro/Toolsintro'

function Aboutus() {
    return (
        <div><About /><Toolsintro /></div>
    )
}

export default Aboutus