import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCcA8taZ2SE7mva65gD6oqlm2O8DTJa3tw",
  authDomain: "ailogixxcom.firebaseapp.com",
  projectId: "ailogixxcom",
  storageBucket: "ailogixxcom.appspot.com",
  messagingSenderId: "857936408397",
  appId: "1:857936408397:web:332e10e4497994cb071104",
  measurementId: "G-PJMSXE3XWC"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

// Initialize Google and Facebook Providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { googleProvider, facebookProvider };
