import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../Source/firebaseConfig";
import Comment from '../LikeDeleteCooment/Comment';
import './Article.css';

export default function Article() {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);  // State for image loading

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Query to find the article by slug
        const articlesRef = collection(db, "Articles");
        const q = query(articlesRef, where("slug", "==", slug));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const articleData = querySnapshot.docs[0].data();
          setArticle({ ...articleData, id: querySnapshot.docs[0].id });
        } else {
          console.error("No article found with this slug");
        }
      } catch (error) {
        console.error("Error fetching article: ", error);
      }
    };

    fetchArticle();
  }, [slug]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="container">
      {article ? (
        <div className="blogItem">
          <div className="blogposttitle">
            <h2>{article.title}</h2>
          </div>

          <div className="authordatea">
            <h5>Author:<span style={{ color: "orange" }}>{article.Author} </span></h5>
            <div className="date">
              <p>{article.createdAt.toDate().toDateString()}</p>
            </div>
          </div>

          <div className="blogpostimg">
            <img
              src={article.imageUrl}
              alt={article.title}
              className={`article-image ${imageLoaded ? 'image-loaded' : ''}`}
              onLoad={handleImageLoad} // Triggers when the image is fully loaded
            />
          </div>

          <div className="autherdateallcontiner">
            {/* Main content only */}
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
          </div>

          <div className="Blogpostcomment">
            <Comment id={article.id} />
          </div>
        </div>
      ) : (
        <p>No article found with this slug</p>
      )}
    </div>
  );
}
