import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../Source/firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Footer from "../../Footer/Footer";

export default function PostTool() {
    const [user] = useAuthState(auth);
    const [formData, setFormData] = useState({
        title: "",
        content: "", // For rich text content
        image: "",
        Author: "",
        createdAt: Timestamp.now().toDate(),
        Type: "",
        OfficalLink: "",
    });

    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleContentChange = (value) => {
        setFormData({ ...formData, content: value });
    };

    const handleImageChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handlePublish = () => {
        if (!formData.title || !formData.content || !formData.image) {
            alert("Please fill all the fields");
            return;
        }

        const storageRef = ref(
            storage,
            `/Toolsimages/${Date.now()}${formData.image.name}`
        );

        const uploadImage = uploadBytesResumable(storageRef, formData.image);

        uploadImage.on(
            "state_changed",
            (snapshot) => {
                const progressPercent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progressPercent);
            },
            (err) => {
                console.log(err);
            },
            () => {
                setFormData({
                    title: "",
                    content: "",
                    image: "",
                    Author: "",
                    Type: "",
                    OfficalLink: "",
                });

                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    const articleRef = collection(db, "Tools");
                    addDoc(articleRef, {
                        title: formData.title,
                        content: formData.content,
                        imageUrl: url,
                        createdAt: Timestamp.now().toDate(),
                        createdBy: user.displayName,
                        userId: user.uid,
                        Type: formData.Type,
                        Author: formData.Author,
                        OfficalLink: formData.OfficalLink,
                        likes: [],
                        comments: []
                    })
                        .then(() => {
                            toast("Tool info added successfully", { type: "success" });
                            setProgress(0);
                        })
                        .catch((err) => {
                            toast("Error adding Tool info", { type: "error" });
                        });
                });
            }
        );
    };

    return (<>
        <div className="container">
            <div className="p-3 mt-3">
                {!user ? (
                    <>
                        <h2>
                            <span style={{ color: "#ecb706" }}>LOGIN TO TELL US ABOUT A NEW TOOL</span>
                        </h2>
                        Don't have an account? <Link to="/register">Signup</Link> <br />
                        <p>I have an account?<Link to="/login">LOGIN</Link></p>
                    </>
                ) : (
                    <>
                        <h2 className="secoundparm" style={{ textAlign: "center", textTransform: "capitalize" }}>Tell us about a new tool</h2>
                        <div className="form-group">
                            <label htmlFor="" style={{ fontWeight: "bold", }}>Author</label>
                            <input
                                type="text"
                                name="Author"
                                className="form-control"
                                value={formData.Author}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" style={{ fontWeight: "bold", }}>Type</label>
                            <input
                                type="text"
                                name="Type"
                                className="form-control"
                                value={formData.Type}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="" style={{ fontWeight: "bold", }}>Add Your Tool Title</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                value={formData.title}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <label htmlFor="" style={{ fontWeight: "bold", }}>Content</label>
                        <ReactQuill
                            value={formData.content}
                            onChange={handleContentChange}
                            modules={PostTool.modules}
                            formats={PostTool.formats}
                        />

                        <div className="form-group">
                            <label htmlFor="" style={{ fontWeight: "bold", }}>Official Link</label>
                            <input
                                type="text"
                                name="OfficalLink"
                                className="form-control"
                                value={formData.OfficalLink}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>

                        <label htmlFor="" style={{ fontWeight: "bold" }}>Image</label>
                        <p style={{ color: "red" }}>Your image size should be 940px width and 450px height</p>
                        <input
                            type="file"
                            name="image"
                            accept="image/*"
                            className="form-control"
                            onChange={(e) => handleImageChange(e)}
                        />

                        {progress === 0 ? null : (
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-striped mt-2"
                                    style={{ width: `${progress}%` }}
                                >
                                    {`Uploading image ${progress}%`}
                                </div>
                            </div>
                        )}
                        <button
                            className="form-control btn-primary mt-2"
                            onClick={handlePublish}
                        >
                            Publish
                        </button>
                    </>
                )}
            </div>
            <hr />
        </div>
        <Footer />
    </>

    );
}

PostTool.modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ align: [] }],
        ['clean']
    ],
};

PostTool.formats = [
    'header',
    'font',
    'list',
    'bullet',
    'bold',
    'italic',
    'underline',
    'link',
    'align',
];
