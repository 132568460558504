import React from 'react'
import "./Toolsintro.css"
import { Link } from 'react-router-dom'; import Footer from "../../Footer/Footer"
function Toolsintro() {
    return (<> <div id='' className='abouttools'>

        <div class="container" data-aos="fade - up" data-aos-delay="100">
            <div class="row align-items-xl-center gy-5" >

                <div class="col-xl-5 content">
                    <h3>AILogixX.com Tools Section</h3>
                    <h2>AI Tools: A Smart Way to Boost Your Tool Section</h2>
                    <p>In an era where simplification is the key, it only seems prudent to introduce and implement new technological solutions. Here’s the evolution of technology.</p>
                    <Link to="/Tools" style={{ textDecoration: "none" }}><a href="#" class="read-more" style={{ textDecoration: "none" }}><span>Tools</span><i class="bi bi-arrow-right"></i></a></Link>
                </div>

                <div class="col-xl-7">
                    <div class="row gy-4 icon-boxes">

                        <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div class="icon-box">
                                <i class="fa fa-wrench" aria-hidden="true"></i>
                                <h3>Tools</h3>
                                <p>Take a look at our section and find the premium AI tools like the ones that help you in your endeavors in a cut above the rest.</p>
                            </div>
                        </div>

                        <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div class="icon-box">
                                <i class="fa fa-line-chart" aria-hidden="true"></i>
                                <h3>Key Features </h3>
                                <p>Allows our users to visualize key tool functionality at a glance and be informed.</p>
                            </div>
                        </div>

                        <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div class="icon-box">
                                <i class="fa fa-transgender" aria-hidden="true"></i>
                                <h3>CATEGORY</h3>
                                <p>Effortlessly navigate and discover tools by category, tailored to enhance user experience.</p>
                            </div>
                        </div>

                        <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
                            <div class="icon-box">
                                <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                <h3>OFFICAL LINK</h3>
                                <p>Access tools seamlessly through official links for a streamlined user experience</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div >
        </div >
    </div ><Footer /></>

    )
}

export default Toolsintro